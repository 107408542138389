// extracted by mini-css-extract-plugin
export var container = "DeliveryDates-module--container--BWEIv";
export var hero = "DeliveryDates-module--hero--2ehfG";
export var heroText = "DeliveryDates-module--heroText--3SJ7q";
export var heroLive = "DeliveryDates-module--heroLive--2bAY4";
export var blinker = "DeliveryDates-module--blinker--KtdXZ";
export var content = "DeliveryDates-module--content--RhEk9";
export var squares = "DeliveryDates-module--squares--lQxqj";
export var square = "DeliveryDates-module--square--B3oY-";
export var squareHead = "DeliveryDates-module--squareHead--13CQE";
export var squareContent = "DeliveryDates-module--squareContent--1UH8Y";
export var text = "DeliveryDates-module--text--3CZW1";