import { graphql } from 'gatsby';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import { Layout } from '../components';
import { DeliveryDates } from '../components/DeliveryDates/DeliveryDates';
import { LanguageContext } from '../context/LanguageContext';
import { RichTextElements } from '../utils/fragments/types';
import { formatInfoLinks, formatMenuLinks } from '../utils/helpers';
import {
  IAllContentfulCategory,
  IContentfulVacationBanner,
} from '../utils/types';

interface IDeliveryDatesProps {
  data: {
    contentfulPage: {
      subHeading: string;
      pageContent: RenderRichTextData<RichTextElements>;
    };
    contentfulVacationBanner?: IContentfulVacationBanner;
    allContentfulCategory: IAllContentfulCategory;
    allContentfulPrivacyAndToS: {
      nodes: {
        title: string;
        url: string;
      }[];
    };
  };
}

const DeliveryDatesPage: React.FunctionComponent<IDeliveryDatesProps> = ({
  data,
}) => {
  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, 'De');
  const infoLink = formatInfoLinks(data.allContentfulPrivacyAndToS.nodes, 'De');

  return (
    <LanguageContext.Provider value={'De'}>
      <Layout
        links={menuLink}
        infoLinks={infoLink}
        vacationBanner={data.contentfulVacationBanner}
      >
        <DeliveryDates
          title={data.contentfulPage.subHeading}
          description={data.contentfulPage.pageContent}
        />
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
  {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: "De" } }
    ) {
      nodes {
        ...category
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: "De" } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulPage(slug: { eq: "lieferzeiten" }) {
      # had to use the subHeading because using the title can
      # affect the slug which we use to query the page content
      subHeading
      pageContent {
        raw
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default DeliveryDatesPage;
