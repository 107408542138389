import {
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import { RichTextElements } from '../../utils/fragments/types';
import { useTranslate } from '../../utils/translation/useTranslation';

import * as styles from './DeliveryDates.module.less';

interface DeliveryDatesResponse {
  CurrentDeliveryStatus: {
    CurrentDeliveryTimeWood: string;
    CurrentDeliveryTimeWoodAlu: string;
    CurrentDeliveryTimeFireResistance: string;
  };
  Result: boolean;
}

export const DeliveryDates: React.FunctionComponent<{
  title: string;
  description: RenderRichTextData<RichTextElements>;
}> = ({ title, description }) => {
  const [holzDate, setHolzDate] = React.useState({
    durationinWeeks: '0',
    textWeek: '0',
  });
  const [holzAluDate, setHolzAluDate] = React.useState({
    durationinWeeks: '0',
    textWeek: '0',
  });
  const [fireResDate, setFireResDate] = React.useState({
    durationinWeeks: '0',
    textWeek: '0',
  });

  React.useEffect(() => {
    fetch(
      'https://lead.sorpetaler.de:887/app/ProductionStatusManager/GetNewProduktionStatus'
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error('request error');
      })
      .then((res: DeliveryDatesResponse) => {
        const holzAluinWeeks = res.CurrentDeliveryStatus.CurrentDeliveryTimeWoodAlu.split(
          ' '
        )[0];
        const holzAluWeekText = res.CurrentDeliveryStatus.CurrentDeliveryTimeWoodAlu.split(
          ' '
        )[1];
        const holzinWeeks = res.CurrentDeliveryStatus.CurrentDeliveryTimeWood.split(
          ' '
        )[0];
        const holzWeekText = res.CurrentDeliveryStatus.CurrentDeliveryTimeWood.split(
          ' '
        )[1];
        const fireinWeeks = res.CurrentDeliveryStatus.CurrentDeliveryTimeFireResistance.split(
          ' '
        )[0];
        const fireWeekText = res.CurrentDeliveryStatus.CurrentDeliveryTimeFireResistance.split(
          ' '
        )[1];
        if (holzAluinWeeks !== 'neue')
          setHolzAluDate({
            durationinWeeks: holzAluinWeeks,
            textWeek: holzAluWeekText,
          });
        if (holzinWeeks !== 'neue')
          setHolzDate({ durationinWeeks: holzinWeeks, textWeek: holzWeekText });
        if (fireinWeeks !== 'neue')
          setFireResDate({
            durationinWeeks: fireinWeeks,
            textWeek: fireWeekText,
          });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const longWordStyle = { fontSize: '78px', lineHeight: '1.6' };
  const weekTextStyle = { fontSize: '44px', lineHeight: '1' };
  const { t } = useTranslate();
  const listOfWords = title.split(' ');

  return (
    <div className={styles.container}>
      <div className={styles.hero}>
        <h1 className={styles.heroText}>
          {React.Children.toArray(
            listOfWords.map((word) => <span>{word}</span>)
          )}
        </h1>
        <div className={styles.heroLive}>
          <div>
            <div>live</div>
            <div></div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.squares}>
          <div className={styles.square}>
            <div className={styles.squareHead}>
              <span>{t('wood')}</span>
            </div>
            <div className={styles.squareContent}>
              <div style={longWordStyle}>{holzDate.durationinWeeks}</div>
              <div style={weekTextStyle}>{holzDate.textWeek}</div>
            </div>
          </div>
          <div className={styles.square}>
            <div className={styles.squareHead}>
              <span>
                {t('wood')} - {t('aluminum')}
              </span>
            </div>
            <div className={styles.squareContent}>
              <div style={longWordStyle}>{holzAluDate.durationinWeeks}</div>
              <div style={weekTextStyle}>{holzAluDate.textWeek}</div>
            </div>
          </div>
          <div className={styles.square}>
            <div className={styles.squareHead}>
              <span style={{ fontSize: '20px' }}>{t('fireresistance')}</span>
            </div>
            <div className={styles.squareContent}>
              <div style={longWordStyle}>{fireResDate.durationinWeeks}</div>
              <div style={weekTextStyle}>{fireResDate.textWeek}</div>
            </div>
          </div>
        </div>
        <div className={styles.text}>{renderRichText(description)}</div>
      </div>
    </div>
  );
};
